
.autocomplete_tag {
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.autocomplete_tag ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.autocomplete_tag .tag_container {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
}

.autocomplete_tag .selected_tag_list {
    display: flex;
    flex-wrap: wrap;
}

.autocomplete_tag .tag_item {
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: .25rem;
    background-color: #ececf9;
    width: max-content;
    margin-right: .5rem;
    margin-bottom: .5rem;
}

.autocomplete_tag .tag_input {
    border: none;
    background-color: #eeeeee;
    height: calc(1.5em + 0.75rem + 2px)
}

.autocomplete_tag .tag_item .tag_close {
    cursor: pointer;
    padding: 0 5px;
}

.autocomplete_tag .tag_item .tag_close:hover {
    background-color: #cccccc;
    border-radius: 5px;
}

.autocomplete_tag .tag_autosuggestions {
    border-top: 1px solid #cccccc;
}

.autocomplete_tag .suggestion_item {
    padding: 0.5rem;
}

.autocomplete_tag .suggestion_item_hover {
    cursor: pointer;
    background-color: #cccccc;
}
